.Home {
  background: rgb(0,212,255);
  background: linear-gradient(90deg, rgba(0,212,255,1) 0%, rgba(9,81,93,1) 100%);
  
  padding-top: 5%;
  padding-bottom: 5%;
  position: relative;
  
}

h2 {
  margin-top: -30px;
}

.Home .Container h1 {
  
  text-align: center;
  
}
.Home .Container h2 {
  text-align: center;
}

h5 {
  margin-top: -40px;
  text-align: center;
  cursor: pointer;
  
  border:1px solid;
  padding: 3px;
}

h5:hover {
  background-color: black;
}

.Home .Headshot{
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 500px;
  Width: 80vh;
  border-radius: 50%;
}

.Home .Container .Hello {
  font-size: calc(1.76rem + 3vw);
} 

.Resume {
  width: 250px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 20px;
  text-align: center;
}

@media (max-width:1300px) {
  .Resume {
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 20px;
    text-align: center;
   }
  }

@media (max-width:1000px) {
.Resume {
  width: 175px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 20px;
  text-align: center;
 }
}

@media (max-width:1000px){
  .Home {
    padding-top: 8%;
  }
  .Home .Headshot {
    Width: 40vh;
  }
  h1{
    padding-bottom: 15px;
  }
  h2 {
    padding-bottom: 15px;
  }
}


@media (max-width:740px){
  .Home {
    padding-top: 10%;
  }
  .Home .Headshot {
    Width: 40vh;
  }
  h1{
    padding-bottom: 10px;
  }
  h2 {
    padding-bottom: 10px;
  }
}

@media (max-width:520px){
  .Home {
    padding-top: 15%;
  }
  .Home .Headshot {
    Width: 40vh;
  }
}

@media (max-width:400px){
  .Home {
    padding-top: 20%;
  }
  .Home .Headshot {
    Width: 30vh;
  }
}
@media (max-width:325px){
  .Home {
    padding-top: 25%;
  }
  .Home .Headshot {
    Width: 30vh;
  }
}