.AboutMe {
  background-color: black;
  padding-bottom: 5%;
}


.AboutMe .Container {
  margin-left: 10%;
  margin-right: 10%;
  text-align: center;
}

/* .AboutMe .Container .Photo {
  display: flex;
  width: 50%;
  justify-content: center;
  align-items: center;
  
}
.AboutMe .Container {
  display: flex;
}

.AboutMe .Container .Text{
  width: 47%;
  margin-left: 3%;
}

.AboutMe .Container .Text h2{
  font-weight: 800;
} 

@media (max-width: 500px) {
  .AboutMe .Container {
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 500px){
.AboutMe .Container .Text{
  width: 80%;
  margin-left: 3%;
  }
}

@media (max-width: 500px){
  .AboutMe .Container .Photo {
    width: 70%;
    }
  } */

