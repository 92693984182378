.Skills {
  background-color: black;
  padding-bottom: 5%;
}

.Skills .List ul {
  color: white;
  list-style-type: none;
  padding: 0;
}

.Skills .Container {
  display: flex;
  flex-wrap: wrap;
  margin-left: 10%;
  margin-right: 10%;
  justify-content: space-around;
}

.Skills .Container .List {
  flex-basis: 33%;
  background-color: black;
  box-shadow: 0 0 1vw .3vw white;
  border-radius: 9px;
  margin-bottom: 2px;
}

.Skills .List ul li {
  display: flex;
  justify-content: center;
}

.Skills p {
  padding-left: 25%;
  width: 50%;
}

.Skills .Languages {
  display: flex;
  justify-content: center;
  width: 40%;
  height: 40%;
  border-radius: 9px;
  margin-bottom: 3%;
  margin-left: 30%;
  margin-right: 30%;
}



.Title {
  font-weight: 900;
  color: #00d4ff;
  margin-bottom: 4px;
}

@media (max-width: 910px){
  .Skills .Languages {
    width: 70%;
    margin-left: 15%;
    margin-right: 15%;
  }
}

@media (max-width: 800px){
.Skills p {
  padding-left: 15%;
  width: 70%;
  }
}

@media (max-width: 800px) {
  .Skills .Container {
    margin-left: 2%;
    margin-right: 2%;
  }
}