.Container {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  padding-bottom: 2%;
}

.Container hr {
  position: absolute;
  left: 0;
  width: 12vw;
  border: 2px  solid #00d4ff
}

.Container h1 {
  color: white;
}