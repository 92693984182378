.Projects {
  background-color: black;
}

.Projects .Paragraph {
  width :65%
}

.Projects .AppContainer .Thumbnail img {
  max-width:489px;
  width: 100%;
}

.Projects .Apps .AppContainer {
  display: flex;
  background: black;
  margin-bottom: 5%;
  height:100%;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0px 0px 5px 1px white;
}

.Projects .Apps {
  width: 70%;
}

.Projects .Content {
  display: flex;
  flex-direction: column;
  align-items: center

}

.Projects .AppContainer .Text {
  padding:2.5%;
  padding-right: 2.5%;
  width: 45%;
  height:95%
}

.Projects .AppContainer .Text .Title {
  font-weight: 800;
}

.Projects .AppContainer .Thumbnail {
  padding: 2.5% 2.5% 2.5% 2.5%;
  position: relative;
  width:45%;
}

@media (max-width:756px ) {
  .Projects .Apps .AppContainer {
    flex-direction: column;
    
  }

  .Projects .Apps .Text {
    width: 80%;
  }
    .Projects .Apps .Thumbnail {
      width: 80%;
      padding-top: 5%;
    }
}

@media (max-width:500px ) {


  .Projects .Apps .Text {
    width: 90%;
  }
    .Projects .Apps .Thumbnail {
      width: 90%;
      padding-top: 5%;
    }
}