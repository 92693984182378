.NavBar {
  background: black;
  height: 80px;
  width: 100%;
  position: fixed;
  z-index: 999;
  opacity: 0.9;
  display: flex;
  justify-content: center;
  align-items: center;
}

.NavBar nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.NavBar nav ul li {
  display: inline-block;
  margin: 1.5vw;
}

.NavBar nav ul li a {
  text-decoration: none;
  color: white;
}

.NavBar a:hover {
  border-bottom: 5px solid #00d4ff;
  
}