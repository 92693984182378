.Contacts {
  background-color: black;
}

.Contact {
  
  height: 14vh;
  display: flex;
  align-items: center;
}



 .Contacts .Contact .ContactIcons {
  width: 100%;
  display: flex;
  justify-content: center;
}


.Contacts .Contact .ContactIcons svg {
  cursor: pointer;
}

/* @media (max-width: 1000px) {
  .Contacts .Contact .Email{
    font-size: 1rem;
  }
  
  .Contacts .Contact .Phone{
    font-size: 1rem;
    
  }
} 

@media (max-width: 584px) {
  .Contacts .Contact .Email{
    font-size: .8rem;
  }
  
  .Contacts .Contact .Phone{
    font-size: .8rem;
    
  }
} 

@media (max-width: 485px) {
  .Contacts .Contact .Email{
    font-size: .6rem;
  }
  
  .Contacts .Contact .Phone{
    font-size: .6rem;
    
  }
} 

@media (max-width: 393px) {
  .Contacts .Contact .Email{
    font-size: .5rem;
  }
  
  .Contacts .Contact .Phone{
    font-size: .5rem;
    
  }
} 

@media (max-width: 350px) {
  .Contacts .Contact .Email{
    font-size: .45rem;
  }
  
  .Contacts .Contact .Phone{
    font-size: .45rem;
    
  }
}  */